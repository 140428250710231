import { useMemo, useState } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from "react-table";
import "../CSS/LibraryTable.css";
import { useNavigate } from "react-router-dom";
import { app } from "../firebase";
import { getDatabase, ref, remove } from "firebase/database";
import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";

const db = getDatabase(app);
const storage = getStorage(app);

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 10)

  return (
    <input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search ${count} books...`}
    />
  )
}

function DocumentTable({ uid, data }) {
  let navigate = useNavigate();

  const deleteDoc = async (lang, id) => {
    await remove(ref(db, `customers/${uid}/progress/${lang}/docs/${id}`));
    await remove(ref(db, `customers/${uid}/documents/${id}`));
    await deleteObject(storageRef(storage, `customers/${uid}/docs/${id}-foreign.epub`));
    await deleteObject(storageRef(storage, `customers/${uid}/docs/${id}-english.epub`));
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Author',
        accessor: 'author',
      },
      {
        Header: 'Filename',
        accessor: 'docId',
      },
      {
        Header: 'Title',
        accessor: 'name',
      },
      {
        Header: 'Language',
        accessor: 'language',
      },
      {
        Header: 'Last Commit',
        accessor: 'createdOn',
      },
      {
        Header: 'Percent read',
        accessor: 'percent',
      }
    ],
    []
  )

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      autoResetFilters: false
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th className="langCol" {...headerGroup.headers[3].getHeaderProps()}>Language</th>
            <th className="authorCol" {...headerGroup.headers[0].getHeaderProps()}>Author</th>
            <th className="bookCol" {...headerGroup.headers[2].getHeaderProps()}>Title</th>
            <th className="progressCol" {...headerGroup.headers[5].getHeaderProps()}>Read</th>
            <th className="progressCol">X</th>
          </tr>
        ))}
        <tr>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        <tr>
          <td className="inputCol" colSpan="5">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </td>
        </tr>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              title={row.cells[0].value + " — " + row.cells[2].value}  
              {...row.getRowProps()}
            >
              <td className="langCol" {...row.cells[3].getCellProps()}
                onClick={() => navigate(`/reader/${row.cells[3].value}/docs/${row.cells[1].value}`)}
              >
                {row.cells[3].value.replace(/^./, str => str.toUpperCase())}
              </td>
              <td className="authorCol" {...row.cells[0].getCellProps()}
                onClick={() => navigate(`/reader/${row.cells[3].value}/docs/${row.cells[1].value}`)}
              >
                {row.cells[0].render('Cell')}
              </td>
              <td className="bookCol" {...row.cells[2].getCellProps()}
                onClick={() => navigate(`/reader/${row.cells[3].value}/docs/${row.cells[1].value}`)}
              >
                {row.cells[2].render('Cell')}
              </td>
              <td className="progressCol" {...row.cells[5].getCellProps()}>
                <b>{row.cells[5].value !== "NaN%" && row.cells[5].render('Cell')}</b>
              </td>
              <td className="progressCol"
                onClick={() => deleteDoc(row.cells[3].value, row.cells[1].value)}
              >
                <b>X</b>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default DocumentTable;