import '../CSS/Reader.css';
import { EpubView } from 'react-reader';
import { useRef, useState, useEffect } from 'react';
import arrowBackIcon from "../Icons/arrow_back.svg"
import chevronLeftIcon from "../Icons/chevron_left.svg"
import menuIcon from "../Icons/menu.svg"
import arrowForwardIcon from "../Icons/arrow_forward.svg"
import textFormatIcon from "../Icons/text_format.svg"
import blurIcon from "../Icons/blur.svg"
import resetIcon from "../Icons/reset.svg"
import lockIcon from "../Icons/lock.svg"
import pinIcon from "../Icons/pin.svg"
import volumeIcon from "../Icons/volume.svg"
import flashcardAddIcon from "../Icons/flashcard_add.svg"
import stopIcon from "../Icons/stop.svg"
import Toc from './Toc';
import Draggable, { DraggableCore } from 'react-draggable';
import { useParams, Link } from 'react-router-dom';
import ReaderLoading from './ReaderLoading';
import { HighlightWithinTextarea } from "./highlight-within-textarea/index.js"
import { app } from "../firebase";
import { getFunctions, httpsCallable, 
  // connectFunctionsEmulator
} from "firebase/functions";
import languageCodes from "../languageCodes";
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';

const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "localhost", 5001);

const storage = getStorage(app);

function Reader(props) {
  let params = useParams();

  const draggableReaderRef = useRef(null);
  const leftRef = useRef(null);
  const leftRenditionRef = useRef(null);
  const [leftRendition, setLeftRendition] = useState(null);
  const [leftSelection, setLeftSelection] = useState({})
  const [leftSentenceSelection, setLeftSentenceSelection] = useState({})
  const handleLeftTextSelected = (cfiRange, contents) => {
    const multi = (cfiRange.split(",")[1].split("/").length - 1) === 2;

    const originalText = leftRenditionRef.current.getRange(cfiRange).toString();
    const trimmedText = originalText.trim();
    if (trimmedText.length < originalText.length && !multi) {
      const startOffset = originalText.indexOf(trimmedText);
      const adjustedStart = Number(cfiRange.split(":")[1].split(",")[0]) + startOffset;
      const adjustedEnd = adjustedStart + trimmedText.length;
      cfiRange = `${cfiRange.split(":")[0]}:${adjustedStart},${cfiRange.split(",")[2].split(":")[0]}:${adjustedEnd})`;
    }

    setLeftSelection({
      text: trimmedText,
      cfiRange
    })

    const frontPart = cfiRange.substring(cfiRange.indexOf(","), 0);
    let lastChildNode = contents.window.getSelection().baseNode.parentNode.childNodes;

    let sentenceStart = 0;
    let sentenceEnd = Number(lastChildNode[lastChildNode.length - 1].length);
    sentenceEnd = sentenceEnd ? sentenceEnd : 0;

    let sentenceCfi = frontPart + ",/1:" + sentenceStart + ",/" + lastChildNode.length + ":" + sentenceEnd + ")";
    
    let multiLeft, multiRight;
    if (multi) {
      let sel = contents.window.getSelection();
      let position = sel.anchorNode.compareDocumentPosition(sel.focusNode);
      let backward = false;
      // position == 0 if nodes are the same
      if ((!position && sel.anchorOffset > sel.focusOffset) || 
        position === Node.DOCUMENT_POSITION_PRECEDING)
        backward = true; 
      
      if (!backward)
        lastChildNode = contents.window.getSelection().focusNode.parentNode.childNodes;
      
      sentenceEnd = Number(lastChildNode[lastChildNode.length - 1].length);
      sentenceEnd = sentenceEnd ? sentenceEnd : 0;
      
      multiLeft = ",/" + cfiRange.split(",")[1].split("/")[1] + "/";
      multiRight = ",/" + cfiRange.split(",")[2].split("/")[1] + "/";
      sentenceCfi = frontPart + multiLeft + "1:" + sentenceStart + multiRight + lastChildNode.length + ":" + sentenceEnd + ")";
    }
    
    let text = leftRenditionRef.current.getRange(sentenceCfi).toString();
    let textStart = 0;
    let textEnd = text.length;
    let wordStart = text.indexOf(trimmedText);
    let wordEnd = wordStart + trimmedText.length;
    let startEllipses = false;
    let endEllipses = false;
    
    let startPunc = -1;
    if (text.substring(textStart, wordStart).lastIndexOf(".") !== -1)
      if (startPunc === -1 || text.substring(textStart, wordStart).lastIndexOf(".") > startPunc)
        startPunc = text.substring(textStart, wordStart).lastIndexOf(".");
    if (text.substring(textStart, wordStart).lastIndexOf("!") !== -1)
      if (startPunc === -1 || text.substring(textStart, wordStart).lastIndexOf("!") > startPunc)
        startPunc = text.substring(textStart, wordStart).lastIndexOf("!");
    if (text.substring(textStart, wordStart).lastIndexOf("?") !== -1)
      if (startPunc === -1 || text.substring(textStart, wordStart).lastIndexOf("?") > startPunc)
        startPunc = text.substring(textStart, wordStart).lastIndexOf("?");
    if (text.substring(textStart, wordStart).lastIndexOf("…") !== -1)
      if (startPunc === -1 || text.substring(textStart, wordStart).lastIndexOf("…") > startPunc)
        startPunc = text.substring(textStart, wordStart).lastIndexOf("…");
    
    if (startPunc !== -1)
      textStart = startPunc + 1;
    
    if (wordStart - textStart > 100) {
      textStart = wordStart - 100;
      startEllipses = true;
      for (let i = textStart; i < textStart + 20; i++) {
        if (text[i] === ' ') {
          textStart = i + 1;
          break;
        }
      }
    }

    let endPunc = -1;
    if (text.substring(wordEnd, textEnd).indexOf(".") !== -1)
      if (endPunc === -1 || text.substring(wordEnd, textEnd).indexOf(".") < endPunc)
        endPunc = text.substring(wordEnd, textEnd).indexOf(".");
    if (text.substring(wordEnd, textEnd).indexOf("!") !== -1)
      if (endPunc === -1 || text.substring(wordEnd, textEnd).indexOf("!") < endPunc)
        endPunc = text.substring(wordEnd, textEnd).indexOf("!");
    if (text.substring(wordEnd, textEnd).indexOf("?") !== -1)
      if (endPunc === -1 || text.substring(wordEnd, textEnd).indexOf("?") < endPunc)
        endPunc = text.substring(wordEnd, textEnd).indexOf("?");
    if (text.substring(wordEnd, textEnd).indexOf("…") !== -1)
      if (endPunc === -1 || text.substring(wordEnd, textEnd).indexOf("…") < endPunc)
        endPunc = text.substring(wordEnd, textEnd).indexOf("…");
    
    if (endPunc !== -1)
      textEnd = endPunc + wordEnd + 1;
      
    if (textEnd - wordEnd > 100) {
      textEnd = wordStart + 100;
      endEllipses = true;
      for (let i = textEnd; i > textEnd - 20; i--) {
        if (text[i] === ' ') {
          textEnd = i;
          break;
        }
      }
    }

    text = text.substring(textStart, textEnd).trim();
    text = (startEllipses ? "…" : "") + text + (endEllipses ? "…" : "");
    
    setLeftSentenceSelection({
      text
    })
  }
  const rightRef = useRef(null);
  const rightRenditionRef = useRef(null);
  const [rightRendition, setRightRendition] = useState(null);
  const [rightSelection, setRightSelection] = useState({})
  const handleRightTextSelected = (cfiRange, contents) => {
    const multi = (cfiRange.split(",")[1].split("/").length - 1) === 2;

    const originalText = rightRenditionRef.current.getRange(cfiRange).toString();
    const trimmedText = originalText.trim();
    if (trimmedText.length < originalText.length && !multi) {
      const startOffset = originalText.indexOf(trimmedText);
      const adjustedStart = Number(cfiRange.split(":")[1].split(",")[0]) + startOffset;
      const adjustedEnd = adjustedStart + trimmedText.length;
      cfiRange = `${cfiRange.split(":")[0]}:${adjustedStart},${cfiRange.split(",")[2].split(":")[0]}:${adjustedEnd})`;
    }

    setRightSelection({
      text: trimmedText,
      cfiRange
    })
  }

  const [dragStart, setDragStart] = useState(0);
  const [totalDragOffset, setTotalDragOffset] = useState(0);
  const [finishedDrag, setFinishedDrag] = useState(0);
  const [disabledReset, setDisabledReset] = useState(true);
  const [dragEnabled, setDragEnabled] = useState(true);
  const handleDragStart = e => {
    setTotalDragOffset(totalDragOffset + finishedDrag);
    setDragStart(e.clientY)
    setDisabledReset(false);
  };
  const handleDrag = e => {
    let dragOffset = e.clientY - dragStart;
    draggableReaderRef.current.style.transform = "translate(0, " + (dragOffset + totalDragOffset).toString() + "px)";
    setFinishedDrag(dragOffset);
    e.preventDefault();
  };
  const resetDrag = () => {
    setDisabledReset(true);
    setFinishedDrag(0);
    setTotalDragOffset(0);
    setDragStart(0)
    draggableReaderRef.current.style.transform = "";
  }

  const [tocShow, setTocShow] = useState(false);
  const [leftToc, setLeftToc] = useState([]);
  const [leftLocation, setLeftLocation] = useState(props.progress?.[params.category]?.[params.book]?.[params.commit]?.leftLocation);
  const [rightToc, setRightToc] = useState([]);
  const [rightLocation, setRightLocation] = useState(props.progress?.[params.category]?.[params.book]?.[params.commit]?.rightLocation);

  useEffect(() => {
    resetDrag();
  }, [rightLocation]);
  
  const [blur, setBlur] = useState(false);

  const tabFunction = e => {
    if (e.key === "Tab") {
      e.preventDefault();
      setBlur(current => !current);
    }
  }

  const [flashcardEnabled, setFlashcardEnabled] = useState(false);

  const clickFunction = e => {
    if (!(e.target.classList.contains("tocIcon") ||
          e.target.classList.contains("readerTocInner") ||
          e.target.classList.contains("readerTocOuter") ||
          e.target.classList.contains("tocLink") ||
          e.target.classList.contains("tocElement")))
      setTocShow(false);
    if (!(e.target.id === "fontIcon" ||
          e.target.classList.contains("topRightTooltip") ||
          e.target.classList.contains("fontLine") ||
          e.target.classList.contains("fontLabelText") ||
          e.target.classList.contains("fontInput")))
      setFontShow(false);
  }

  const flashcardClickFunction = e => {
    if (!(e.target.id === "volumeIcon" || 
          e.target.id === "flashcardAddIcon"))
      setTimeout(() => setFlashcardEnabled(Boolean(e.view.getSelection().toString().trim())));
  }

  const [originalBlur, setOriginalBlur] = useState(false);

  const resetFlashcard = () => {
    setManualTranslation("");
    setFlashcardShow(false);
    setContextStep(false);
    setFlashcardEnabled(false);
    if (originalBlur) {
      setBlur(true);
      setOriginalBlur(false);
    }
  }

  const locationChanged = (left, v) => {
    resetFlashcard();

    const progressData = {
      bookId: `${params.category}/${params.book}/${params.commit}`,
      left,
      location: v
    }

    if (!progressData.location)
      return;

    let percent = 0;

    if (left) {
      setLeftLocation(v);
      const leftSpine = leftRendition?.book?.spine;
      if (leftSpine) {
        const cfiBase = progressData.location.split("!")[0].split("epubcfi(")[1];
        let progress = leftSpine.items.findIndex(el => el.cfiBase === cfiBase);
        progress = progress === -1 ? 0 : progress
        if (leftSpine.items.length)
        percent = progress / leftSpine.items.length;
      }
    } else {
      setRightLocation(v);
      const rightSpine = rightRendition?.book?.spine;
      if (rightSpine) {
        const cfiBase = progressData.location.split("!")[0].split("epubcfi(")[1];
        let progress = rightSpine.items.findIndex(el => el.cfiBase === cfiBase);
        progress = progress === -1 ? 0 : progress
        if (rightSpine.items.length)
        percent = progress / rightSpine.items.length;
      }
    }

    if (percent > 0.9)
      percent = 1

    progressData.percent = percent;

    const functionRef = httpsCallable(functions, "progressSet");
    
    functionRef(progressData);
  };

  const arrowFunction = e => {
    if (e.key === "ArrowRight") {
      leftRef.current.nextPage();
      rightRef.current.nextPage();
    }
    if (e.key === "ArrowLeft") {
      leftRef.current.prevPage();
      rightRef.current.prevPage();
    }
  }

  const [leftLoaded, setLeftLoaded] = useState(false);
  useEffect(() => {leftRendition?.on("displayed", () => setLeftLoaded(true))}, [leftRendition]);
  const [rightLoaded, setRightLoaded] = useState(false);
  useEffect(() => {rightRendition?.on("displayed", () => setRightLoaded(true))}, [rightRendition]);

  const [leftChapter, setLeftChapter] = useState("");

  useEffect(() => {
    if (leftLoaded)
      setLeftChapter(leftRendition.currentLocation().start?.href);
  }, [leftLocation, leftToc, leftLoaded, leftRendition]);

  const [rightChapter, setRightChapter] = useState("");

  useEffect(() => {
    if (rightLoaded)
      setRightChapter(rightRendition.currentLocation().start?.href);
  }, [rightLocation, rightToc, rightLoaded, rightRendition]);

  useEffect(() => {
    document.addEventListener('keydown', tabFunction)
    document.addEventListener('click', clickFunction)
  }, [])

  useEffect(() => {
    if (rightRendition?.themes && rightRendition?.location) {
      rightRendition.themes.select(blur ? "blur" : 'custom');
      var location = rightRendition.location.start.cfi;
      rightRendition.clear();
      rightRendition.display(location);
    }
  }, [blur, rightRendition]);
  
  const [show, setShow] = useState(false);
  const [uiDontHide, setUiDontHide] = useState(true);

  const mouseOver = e => {
    setShow(true);
  }
  
  const mouseOut = e => {
    setShow(false);
  }
  
  const [iconShow, setIconShow] = useState(false);
  const [leftIconShow, setLeftIconShow] = useState(false);
  const [iconTooltipText, setIconTooltipText] = useState("");
  const [leftIconTooltipText, setLeftIconTooltipText] = useState("");
  const [iconTooltipKeyText, setIconTooltipKeyText] = useState("");
  const [leftIconTooltipKeyText, setLeftIconTooltipKeyText] = useState("");

  const [fontShow, setFontShow] = useState(false);
  const [font, setFont] = useState('Times');
  const fontOptions = [
    { label: 'Times New Roman', value: 'Times' },
    { label: 'Sans-Serif', value: 'sans-serif' },
    { label: 'Garamond', value: 'Garamond' },
  ];
  const handleFontChange = (event) => {
    setFont(event.target.value);
  };
  useEffect(() => {
    if (rightRendition?.themes)
      rightRendition.themes.font(font);
    if (leftRendition?.themes)
      leftRendition.themes.font(font);
  }, [font, leftRendition, rightRendition]);
  
  const [fontSize, setFontSize] = useState(16);
  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
  };
  useEffect(() => {
    if (rightRendition?.themes)
      rightRendition.themes.fontSize(fontSize + "px");
    if (leftRendition?.themes)
      leftRendition.themes.fontSize(fontSize + "px");
  }, [fontSize, leftRendition, rightRendition]);
  
  const [flashcardShow, setFlashcardShow] = useState(false);
  
  useEffect(() => {
    setDragEnabled(!flashcardShow);
    setRightSelection({});
  }, [flashcardShow]);

  const [contextStep, setContextStep] = useState(false);

  const [tempFlashcard, setTempFlashcard] = useState({});
  const [frontContext, setFrontContext] = useState("");
  const [backTranslation, setBackTranslation] = useState("");
  const toContextStep = e => {
    e.preventDefault();
    setTempFlashcard({
      leftSelection,
      rightSelection,
      leftSentenceSelection
    })
    setFrontContext(leftSentenceSelection.text);
    setBackTranslation(rightSelection.text);
    leftRendition.annotations.add("highlight", leftSelection.cfiRange, {}, () => null, "highlight", {});
    rightRendition.annotations.add("highlight", rightSelection.cfiRange, {}, () => null, "highlight", {});
    setContextStep(true);
    document.querySelectorAll("iframe").forEach(frame => frame.contentWindow.getSelection().removeAllRanges());
  }

  const [translateLoading, setTranslateLoading] = useState(false);
  const toContextStepTranslate = e => {
    e.preventDefault();
    setTranslateLoading(true);

    const functionRef = httpsCallable(functions, "translate");
    functionRef({inputText: leftSelection.text, language: languageCodes[params.category]}).then(({ data }) => {
      setTempFlashcard({
        leftSelection,
        rightSelection: { text: data.output },
        leftSentenceSelection
      })
      setFrontContext(leftSentenceSelection.text);
      setBackTranslation(data.output);
      leftRendition.annotations.add("highlight", leftSelection.cfiRange, {}, () => null, "highlight", {});
      setContextStep(true);
      document.querySelectorAll("iframe").forEach(frame => frame.contentWindow.getSelection().removeAllRanges());
      setTranslateLoading(false);
    });
  }

  const [manualTranslation, setManualTranslation] = useState("");
  const toContextStepManual = e => {
    e.preventDefault();
    setTempFlashcard({
      leftSelection,
      rightSelection,
      leftSentenceSelection
    })
    setFrontContext(leftSentenceSelection.text);
    setBackTranslation(manualTranslation.trim());
    leftRendition.annotations.add("highlight", leftSelection.cfiRange, {}, () => null, "highlight", {});
    setContextStep(true);
    document.querySelectorAll("iframe").forEach(frame => frame.contentWindow.getSelection().removeAllRanges());
    setManualTranslation("");
  }

  const [createCardLoading, setCreateCardLoading] = useState(false);
  const createCard = e => {
    setCreateCardLoading(true);

    const functionRef = httpsCallable(functions, "flashcardAdd");

    const flashcardData = {
      foreign: tempFlashcard.leftSelection.text,
      context: frontContext,
      english: backTranslation,
      public: params.book !== "docs",
      language: languageCodes[params.category],
      bookId: `${params.category}/${params.book}/${params.commit}`,
      cfi: tempFlashcard.leftSelection.cfiRange,
      leftLocation,
      rightLocation
    }

    functionRef(flashcardData).then(({ data }) => {
      resetFlashcard();
      setCreateCardLoading(false);
    });
  }

  const contextCancel = e => {
    leftRendition.annotations.remove(tempFlashcard.leftSelection.cfiRange, "highlight");
    if (tempFlashcard.rightSelection.cfiRange)
      rightRendition.annotations.remove(tempFlashcard.rightSelection.cfiRange, "highlight");
    resetFlashcard();
  }

  const flashcardCancel = e => {
    resetFlashcard();
    document.querySelectorAll("iframe").forEach(frame => frame.contentWindow.getSelection().removeAllRanges());
  }

  const iconMouseOver = e => {
    if (fontShow) return;
    switch (e.target.id) {
      case "resetIcon":
        setIconTooltipText("Reset right pane drag");
        setIconTooltipKeyText("");
        setIconShow(true);
        break;
      case "lockIcon":
        setIconTooltipText("Lock UI (stop UI hiding)");
        setIconTooltipKeyText("");
        setIconShow(true);
        break;
      case "blurIcon":
        setIconTooltipText("Right pane blur");
        setIconTooltipKeyText("Press TAB to toggle");
        setIconShow(true);
        break;
      case "pinIcon":
        setIconTooltipText("Pin right pane (disable dragging)");
        setIconTooltipKeyText("");
        setIconShow(true);
        break;
      case "fontIcon":
        setIconTooltipText("Font settings");
        setIconTooltipKeyText("");
        setIconShow(true);
        break;
      default:
        setIconShow(false);
    }
  }
  
  const iconMouseOut = e => {
    setIconShow(false);
  }

  const leftIconMouseOver = e => {
    if (flashcardShow) return;
    switch (e.target.id) {
      case "volumeIcon":
        setLeftIconTooltipText("Read selection aloud");
        setLeftIconTooltipKeyText("Microsoft Edge recommended");
        setLeftIconShow(true);
        break;
      case "flashcardAddIcon":
        setLeftIconTooltipText("Create flashcard from selection");
        setLeftIconTooltipKeyText("Select a word or phrase");
        setLeftIconShow(true);
        break;
      case "stopIcon":
        setLeftIconTooltipText("Stop reading selection aloud");
        setLeftIconTooltipKeyText("");
        setLeftIconShow(true);
        break;
      default:
        setLeftIconShow(false);
    }
  }
  
  const leftIconMouseOut = e => {
    setLeftIconShow(false);
  }

  const [speechReading, setSpeechReading] = useState(false);

  var myTimeout;
  function myTimer() {
      window.speechSynthesis.pause();
      window.speechSynthesis.resume();
      myTimeout = setTimeout(myTimer, 14000);
  }

  const readAloud = () => {
    setSpeechReading(true);
    myTimeout = setTimeout(myTimer, 14000);
    var msg = new SpeechSynthesisUtterance();
    msg.onend = e => {setSpeechReading(false); clearTimeout(myTimeout);};
    msg.text = leftSelection?.text;
    const mappings = {
      ...languageCodes,
      "latin": "it"
    };
    msg.lang = mappings[params.category];
    window.speechSynthesis.speak(msg);
  }

  const encodeWiktionary = () => {
    let uppercaseLang = params.category.replace(/^./, str => str.toUpperCase());
    switch (params.category) {
      case "literary-chinese":
        uppercaseLang = "Literary_Chinese"
        break;
      case "ancient-greek":
        uppercaseLang = "Ancient_Greek"
        break;
      case "serbo-croatian":
        uppercaseLang = "Serbo-Croatian"
        break;
      default:
        break;
    }
    return encodeURIComponent(leftSelection?.text + "#" + uppercaseLang)
  }

  const [foreignUrl, setForeignUrl] = useState("");
  const [englishUrl, setEnglishUrl] = useState("");
  useEffect(() => {
    if (params.book === "docs") {
      getDownloadURL(storageRef(storage, `customers/${props.uid}/docs/${params.commit}-foreign.epub`)).then(url => setForeignUrl(url));
      getDownloadURL(storageRef(storage, `customers/${props.uid}/docs/${params.commit}-english.epub`)).then(url => setEnglishUrl(url));
    }
  }, [params.book, params.commit, props.uid])

  return (
    <div className="mainPage">
      <div className="splitReader left">
        <div>
          <EpubView
            ref={leftRef}
            url={params.book === "docs" ? foreignUrl : `https://cdn.jsdelivr.net/gh/langduet/books@${params.commit}/${params.category}/${params.book}/foreign.epub`}
            epubOptions={{
              flow: "scrolled-doc",
              allowScriptedContent: true
            }}
            getRendition={(rendition) => {
              leftRenditionRef.current = rendition;
              setLeftRendition(rendition);
              rendition.themes.register('custom', {
                body: {
                  margin: 0
                }
              })
              rendition.themes.select('custom')
              rendition.themes.fontSize(`16`)
              rendition.on("rendered", (e,i) => {;
                i.document.documentElement.addEventListener('keydown', tabFunction)
                i.document.documentElement.addEventListener('click', clickFunction)
                i.document.documentElement.addEventListener('click', flashcardClickFunction)
              });
            }}
            tocChanged={setLeftToc}
            handleKeyPress={arrowFunction}
            handleTextSelected={handleLeftTextSelected}
            location={leftLocation}
            locationChanged={v => locationChanged(true, v)}
            loadingView={<ReaderLoading />}
          />
        </div>
        <div
          className={(flashcardShow && !contextStep ? "" : "disabled ") + "hideArea"}
        />
      </div>
      <div className="splitReader right">
      <div ref={draggableReaderRef}>
        <EpubView
          ref={rightRef}
          url={params.book === "docs" ? englishUrl : `https://cdn.jsdelivr.net/gh/langduet/books@${params.commit}/${params.category}/${params.book}/english.epub`}
          epubOptions={{
            flow: "scrolled-doc",
            allowScriptedContent: true
          }}
          getRendition={(rendition) => {
            rightRenditionRef.current = rendition;
            setRightRendition(rendition);
            rendition.themes.register('custom', {
              body: {
                margin: 0,
                fontFamily: "sans-serif"
              }
            })
            rendition.themes.register('blur', {
              body: {
                margin: 0,
                filter: "blur(4px)"
              }
            })
            rendition.themes.select(blur ? "blur" : 'custom')
            rendition.themes.fontSize(`16`)
            rendition.on("rendered", (e,i) => {;
              i.document.documentElement.addEventListener('keydown', tabFunction)
              i.document.documentElement.addEventListener('click', clickFunction)
            });
          }}
          tocChanged={setRightToc}
          handleKeyPress={arrowFunction}
          handleTextSelected={handleRightTextSelected}
          location={rightLocation}
          locationChanged={v => locationChanged(false, v)}
          loadingView={<ReaderLoading />}
        />
      </div>
      <DraggableCore
          onStart={handleDragStart}
          onDrag={handleDrag} 
          onStop={e => e.preventDefault()}
      >
        <div 
          draggable="true" 
          className={(dragEnabled ? "" : "disabled ") + "dragArea grab"}
        />
      </DraggableCore>
      </div>
      <div onMouseOver={mouseOver} onMouseOut={mouseOut} className={(!show && !uiDontHide ? "hidden " : "") + "readerHideable readerTop"}>
        <div className={(tocShow ? "" : "disabled ") + "readerTocOuter left"}>
          <div className="readerTocInner">
            <Toc current={leftChapter} toc={leftToc} spine={leftRef.current?.book?.spine} setLocation={setLeftLocation} />
          </div>
        </div>
        <div className={(tocShow ? "" : "disabled ") + "readerTocOuter right"}>
          <div className="readerTocInner">
            <Toc current={rightChapter} toc={rightToc} spine={rightRef.current?.book?.spine} setLocation={setRightLocation} />
          </div>
        </div>
        <div className="readerControls top left">
          {params.book !== "docs" ?
            <Link to="/">
              <span onClick={() => null} className="readerControlsText">
                <img src={chevronLeftIcon} alt="TOC" className="readerControlsTextIcon" />
                <span>Library</span>
              </span>
            </Link> :
            <Link to="/documents">
              <span onClick={() => null} className="readerControlsText">
                <img src={chevronLeftIcon} alt="TOC" className="readerControlsTextIcon" />
                <span>Docs&nbsp;</span>
              </span>
            </Link>
          }
        </div>
        <div className="readerControlsFlex top left flashcardIcons">
          {!speechReading ? 
            <img 
              onMouseOver={leftIconMouseOver} 
              onMouseOut={leftIconMouseOut} 
              onClick={() => {
                if (flashcardEnabled) {
                  readAloud(); 
                  leftIconMouseOver({"target": {"id": "stopIcon"}});
                }
              }}
              className={(flashcardEnabled ? "" : "disabledIcon ") + "readerControlsIcon"} 
              src={volumeIcon} 
              alt="TOC" 
              id="volumeIcon" 
            />
            :
            <img 
              onMouseOver={leftIconMouseOver} 
              onMouseOut={leftIconMouseOut} 
              onClick={() => {window.speechSynthesis.cancel(); leftIconMouseOver({"target": {"id": "volumeIcon"}})}}
              className={"readerControlsIcon"} 
              src={stopIcon} 
              alt="TOC" 
              id="stopIcon" 
            />}
          <img 
            onMouseOver={leftIconMouseOver} 
            onMouseOut={leftIconMouseOut} 
            onClick={() => {
              if (flashcardEnabled) {
                setLeftIconShow(false); 
                setFlashcardShow(true);
                if (blur) {
                  setOriginalBlur(true);
                  setBlur(false);
                }
              }
            }} 
            className={(flashcardEnabled && !flashcardShow ? "" : "disabledIcon ") + (flashcardShow ? "selected " : "") + "readerControlsIcon"} 
            src={flashcardAddIcon} 
            alt="TOC" 
            id="flashcardAddIcon" 
          />
        </div>
        <div className={(leftIconShow && !tocShow ? "" : "disabled ") + "topLeftTooltip"}>
          {leftIconTooltipText}
          <div className={(leftIconTooltipKeyText ? "tooltipKey" : "disabled")}><em>{leftIconTooltipKeyText}</em></div>
        </div>
        {!contextStep ?
          <div className={(flashcardShow ? "" : "disabled ") + "topLeftTooltip"}>
            <div className="flashcardTitle"><span className="flashcardTitleText">Creating a flashcard...</span>{!props.pro && <span title="Upgrade to create more cards" className="freeQuota">{props.freeQuota}/15</span>}</div>
            {!props.pro && props.freeQuota >= 15 ?
            <div>
              You've reached the maximum monthly flashcards for free users. Upgrade to pro to keep going.
            </div> :
            <div>
              <div className="flashcardStep firstPhrase">Selected word/phrase: <strong>{leftSelection?.text}</strong> <a className="wiktionary" href={"https://wiktionary.org/wiki/" + encodeWiktionary()} target="_blank" rel="noreferrer">[dict.]</a></div>
              {!rightSelection?.text ?
                <div className="flashcardStep"><strong>Select</strong> the matching word/phrase in the translation.</div>
                :
                <div className="flashcardStep">Selected translation: <strong>{rightSelection?.text}</strong></div>
              }
              <div className="flashcardButtons">
                <button onClick={toContextStep} disabled={!rightSelection?.text && !translateLoading} className={((rightSelection?.text && !translateLoading) ? "" : "buttonDisabled ") + "flashcardCreate flashcardButton"}>Next</button>
                <button onClick={flashcardCancel} className="flashcardCancel flashcardButton">Cancel</button>
              </div>
              <div className="flashcardOr"><span>or</span></div>
              <div className="translateButton">
                <button onClick={props.pro ? toContextStepTranslate : null} className="flashcardCreate flashcardButton">
                  {!translateLoading ?
                    <>Translate with Google <span className={(props.pro ? "disabled " : "") + "proSpan"}>Pro</span></> :
                    <>Loading...</>
                  }
                </button>
              </div>
              <div className="flashcardOr"><span>or</span></div>
              <div className="manualInput">
                <input value={manualTranslation} onChange={e => setManualTranslation(e.target.value)} onKeyDown={e => !translateLoading && !!e.target.value.trim() && e.key === "Enter" && toContextStepManual(e)} placeholder="Translate manually..."></input>
                <button className={(translateLoading || !manualTranslation.trim()) ? "buttonDisabled" : "manualButton"} disabled={translateLoading || !manualTranslation.trim()} onClick={toContextStepManual}><img src={arrowForwardIcon} alt="Submit" /></button>
              </div>
            </div>}
          </div>
          :
          <Draggable handle="span.grip">
            <div className={(flashcardShow ? "" : "disabled ") + "topLeftTooltip"}>
              <div className="flashcardTitle">Edit and confirm flashcard details. <span>{ [...Array(10)].map((_, index) => <span className="grip" key={index}></span>) }</span></div>
              <div className="flashcardGrid">
                <div className="flashcardLabel">Word/phrase:</div>
                <div className="flashcardTextarea flashcardGray">
                  <span>{tempFlashcard.leftSelection.text}</span>
                </div>
                <div className="flashcardLabel">Context:</div>
                <div className="flashcardTextarea firstPhrase">
                  <HighlightWithinTextarea value={frontContext} highlight={tempFlashcard.leftSelection.text} onChange={selection => setFrontContext(selection)} />
                </div>
                <div className="flashcardLabel">Translation:</div>
                <div className="flashcardTextarea">
                  <input value={backTranslation} onChange={e => setBackTranslation(e.target.value)} />
                </div>
              </div>
              <div className="flashcardButtons">
                <button onClick={createCard} className={(backTranslation.trim() && frontContext.includes(tempFlashcard.leftSelection.text)? "" : "buttonDisabled ") + "flashcardCreate flashcardButton"}>
                  {!createCardLoading ? 
                    <>Create</> :
                    <>Loading...</>
                  }
                </button>
                <button onClick={contextCancel} className="flashcardCancel flashcardButton">Cancel</button>
              </div>
            </div>
          </Draggable>
        }
        <div className="readerControlsFlex top right">
          <img 
            onMouseOver={iconMouseOver} 
            onMouseOut={iconMouseOut} 
            onClick={resetDrag} 
            className={(disabledReset ? "disabledIcon " : "") + "readerControlsIcon"} 
            src={resetIcon} 
            alt="TOC" 
            id="resetIcon" 
          />
          <img 
            onMouseOver={iconMouseOver} 
            onMouseOut={iconMouseOut} 
            onClick={() => setUiDontHide(t => !t)} 
            className={(uiDontHide ? "selected " : "") + "readerControlsIcon"} 
            src={lockIcon} 
            alt="TOC" 
            id="lockIcon" 
          />
          <img 
            onMouseOver={iconMouseOver} 
            onMouseOut={iconMouseOut} 
            onClick={() => setBlur(t => !t)} 
            className={(blur ? "selected " : "") + "readerControlsIcon"} 
            src={blurIcon} 
            alt="TOC" 
            id="blurIcon" 
          />
          <img 
            onMouseOver={iconMouseOver} 
            onMouseOut={iconMouseOut} onClick={() => setDragEnabled(p => !p)} 
            className={(dragEnabled ? "" : "selected ") + "readerControlsIcon"} 
            src={pinIcon} 
            alt="TOC" 
            id="pinIcon" 
          />
          <img 
            onMouseOver={iconMouseOver} 
            onMouseOut={iconMouseOut} onClick={() => {setIconShow(false); setFontShow(p => !p)}} 
            className={(fontShow ? "selected " : "") + "readerControlsIcon"} 
            src={textFormatIcon} 
            alt="TOC" 
            id="fontIcon" 
          />
        </div>
        <div className={(iconShow && !tocShow ? "" : "disabled ") + "topRightTooltip"}>
          {iconTooltipText}
          <div className={(iconTooltipKeyText ? "tooltipKey" : "disabled")}><em>{iconTooltipKeyText}</em></div>
        </div>
        <div className={(fontShow ? "" : "disabled ") + "topRightTooltip"}>
          <div className="fontLabelText">Font: </div>
          <select className="fontInput" value={font} onChange={handleFontChange}>
            {fontOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          <div className="fontLine" />
          <div className="fontLabelText">Font Size: </div>
          <input className="fontInput" value={fontSize} onChange={handleFontSizeChange} type="number" name="fontSize" min="1" max="72" />
        </div>
      </div>
      <div onMouseOver={mouseOver} onMouseOut={mouseOut} className={(!show && !uiDontHide ? "hidden " : "") + "readerHideable readerBottom"}>
        <div className="readerControls bottom left">
          <img onClick={() => leftRef.current.prevPage()} src={arrowBackIcon} alt="TOC" className="readerControlsIcon" />
          <img onClick={() => setTocShow(p => !p)} src={menuIcon} alt="TOC" className={(tocShow ? "selected " : "") + "readerControlsIcon tocIcon"} />
          <img onClick={() => leftRef.current.nextPage()} src={arrowForwardIcon} alt="TOC" className="readerControlsIcon" />
        </div>
        <div className="readerControls bottom center">
          <img onClick={() => {leftRef.current.prevPage(); rightRef.current.prevPage()}} src={arrowBackIcon} alt="TOC" className="readerControlsIcon" />
          <img onClick={() => {leftRef.current.nextPage(); rightRef.current.nextPage()}} src={arrowForwardIcon} alt="TOC" className="readerControlsIcon" />
        </div>
        <div className="readerControls bottom right">
          <img onClick={() => rightRef.current.prevPage()} src={arrowBackIcon} alt="TOC" className="readerControlsIcon" />
          <img onClick={() => setTocShow(p => !p)} src={menuIcon} alt="TOC" className={(tocShow ? "selected " : "") + "readerControlsIcon tocIcon"} />
          <img onClick={() => rightRef.current.nextPage()} src={arrowForwardIcon} alt="TOC" className="readerControlsIcon" />
        </div>
      </div>
    </div>
  );
}

export default Reader;