import Library from './Library';
import Reader from './Reader';
import Documents from './Documents';
import Flashcards from './Flashcards';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { app } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from 'firebase/auth';
import { ref, getDatabase } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import Landing from './Pages/Landing';
import Login from './Pages/Login';
import Register from './Pages/Register';
import { useEffect, useState } from 'react';

const auth = getAuth(app);
const db = getDatabase(app);

function App() {
  const [user] = useAuthState(auth);
  const [pro, setPro] = useState(false);

  useEffect(() => {
    if (user) {
      user.getIdTokenResult(true)
      .then((idTokenResult) => {
        if (!!idTokenResult.claims.stripeRole && idTokenResult.claims.stripeRole === "pro") {
          setPro(true);
        } else {
          setPro(false);
        }
      })
    }
  }, [user]);

  const [progress, progressLoading] = useObjectVal(ref(db, `customers/${user?.uid}/progress`));
  
  const [books] = useListVals(ref(db, `bookInfo`));
  const [bookInfo, setBookInfo] = useState([]);
  useEffect(() => {
    let temp = [];
    books.forEach(category => {
      category.books.forEach(book => {
        let percent = null;
        const leftPercent = progress?.[category.language]?.[book.filename]?.[book.last_commit.substring(0, 7)]?.leftPercent;
        const rightPercent = progress?.[category.language]?.[book.filename]?.[book.last_commit.substring(0, 7)]?.rightPercent;
        if (leftPercent !== null && rightPercent !== null)
          percent = Math.trunc((leftPercent + rightPercent) * 50) + "%";
        temp.push({
          ...book,
          "category": category.language,
          "verified": book.verified ? "V" : "",
          percent
        });
      })
    })
    setBookInfo(temp);
  }, [books, progress]);
  
  const [freeQuota, freeQuotaLoading] = useObjectVal(ref(db, `customers/${user?.uid}/freeQuota/${new Date().toISOString().substring(0, 7)}`), { transform: v => v ? v : 0 });

  if (!user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documents" element={<div />} />
          <Route path="/flashcards" element={<div />} />
          <Route path="/reader/:category/:book/:commit" element={<div />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Library bookInfo={bookInfo} pro={pro} />} />
        <Route path="/documents" element={<Documents progress={progress} uid={user.uid} pro={pro} />} />
        <Route path="/flashcards" element={<Flashcards bookInfo={bookInfo} pro={pro} />} />
        <Route path="/reader/:category/:book/:commit" element={!progressLoading && !freeQuotaLoading && <Reader uid={user.uid} bookInfo={bookInfo} progress={progress} freeQuota={freeQuota} pro={pro} />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
