import { Link } from "react-router-dom";
import "../../CSS/Landing.css";

function Landing() {
  return (
    <div>
      <p>Index</p>
      <p><Link to="/login">Login</Link></p>
      <p><Link to="/register">Register</Link></p>
    </div>
  )
}

export default Landing;