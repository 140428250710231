
import { app } from '../firebase';
import { getAuth, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from 'react';
import { getStripePayments, createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { Link } from 'react-router-dom';

const auth = getAuth(app);
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

function Header(props) {
  const [proLoading, setProLoading] = useState(false);

  const startSubscription = async () => {
    setProLoading(true);
    const session = await createCheckoutSession(payments, {
      price: 'price_1L6h2eJgQbkXSV8x9dMEOYCC',
    });
    console.log(session);
    window.location.assign(session.url);
  }
  
  const goToPortal = async () => {
    setProLoading(true);
    const functions = getFunctions();
    const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto"
    });
    window.location.assign(data.url);
  }

  return (
    <div>
      <button onClick={() => signOut(auth)}>Sign out</button>
      {props.pro ?
        <button onClick={goToPortal}>{proLoading ? "Loading..." : "Manage subscription"}</button> :
        <button onClick={startSubscription}>{proLoading ? "Loading..." : "Get pro"}</button>
      }
      <br />
      <Link to="/"><button>Library</button></Link>
      <Link to="/documents"><button>My Documents</button></Link>
      <Link to="/flashcards"><button>Flashcards</button></Link>
    </div>
  )
}

export default Header;