import "../CSS/Reader.css";

function ReaderLoading() {
  return (
    <div className="loadingContainer">
      <div className="loadingBox">Loading...</div>
    </div>
  )
}

export default ReaderLoading;