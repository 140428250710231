import { useMemo, useState } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from "react-table";
import "../CSS/LibraryTable.css";
import verifiedIcon from "../Icons/verified.svg"
import { useNavigate } from "react-router-dom";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 10)

  return (
    <input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search ${count} books...`}
    />
  )
}

function LibraryTable({ data }) {
  let navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'Verified',
        accessor: 'verified',
      },
      {
        Header: 'Author',
        accessor: 'author',
      },
      {
        Header: 'Filename',
        accessor: 'filename',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Last Commit',
        accessor: 'last_commit',
      },
      {
        Header: 'Percent read',
        accessor: 'percent',
      }
    ],
    []
  )

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      autoResetFilters: false
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th className="verifiedCol" {...headerGroup.headers[0].getHeaderProps()}><img src={verifiedIcon} alt="Verified" className="verifiedIcon" /></th>
            <th className="langCol" {...headerGroup.headers[4].getHeaderProps()}>Language</th>
            <th className="authorCol" {...headerGroup.headers[1].getHeaderProps()}>Author</th>
            <th className="bookCol" {...headerGroup.headers[3].getHeaderProps()}>Title</th>
            <th className="progressCol" {...headerGroup.headers[6].getHeaderProps()}>Read</th>
          </tr>
        ))}
        <tr>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        <tr>
          <td className="inputCol" colSpan="5">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </td>
        </tr>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              onClick={() => navigate(`reader/${row.cells[4].value}/${row.cells[2].value}/${row.cells[5].value.substring(0, 7)}`)} 
              title={row.cells[1].value + " — " + row.cells[3].value}  
              {...row.getRowProps()}
            >
              <td className="verifiedCol" {...row.cells[0].getCellProps()}>
                <img 
                  src={verifiedIcon} 
                  alt="Verified" 
                  className={(row.cells[0].value === "V" ? "" : "hidden ") + "verifiedIcon"} 
                />
              </td>
              <td className="langCol" {...row.cells[4].getCellProps()}>
                {row.cells[4].value.replace(/^./, str => str.toUpperCase())}
              </td>
              <td className="authorCol" {...row.cells[1].getCellProps()}>
                {row.cells[1].render('Cell')}
              </td>
              <td className="bookCol" {...row.cells[3].getCellProps()}>
                {row.cells[3].render('Cell')}
              </td>
              <td className="progressCol" {...row.cells[6].getCellProps()}>
                <b>{row.cells[6].value !== "NaN%" && row.cells[6].render('Cell')}</b>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default LibraryTable;