import Header from "./Header";
import { getStorage, ref as storageRef } from 'firebase/storage';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { app } from "../firebase";
import { useState, useEffect } from "react";
import { getFirestore, doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { ref, getDatabase, push } from 'firebase/database';
import { useListVals } from 'react-firebase-hooks/database';
import DocumentTable from "./DocumentTable";

const storage = getStorage(app);
const firestore = getFirestore(app);
const db = getDatabase(app);

function Documents(props) {
  const [uploadFile] = useUploadFile();
  const [foreignFile, setForeignFile] = useState();
  const [englishFile, setEnglishFile] = useState();
  const [bookName, setBookName] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookLanguage, setBookLanguage] = useState("Other");
  const [customerData] = useDocumentData(doc(firestore, "customers/" + props.uid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const docRef = ref(db, `/customers/${props.uid}/documents`);
  const [documents] = useListVals(docRef, { keyField: "docId" });
  const [documentsProgress, setDocumentsProgress] = useState([]);
  useEffect(() => {
    let temp = [];
    documents.forEach(v => {
      let percent = null;
      const leftPercent = props.progress?.[v.language]?.docs?.[v.docId]?.leftPercent;
      const rightPercent = props.progress?.[v.language]?.docs?.[v.docId]?.rightPercent;
      if (leftPercent !== null && rightPercent !== null)
        percent = Math.trunc((leftPercent + rightPercent) * 50) + "%";
      temp.push({ ...v, percent })
    })
    setDocumentsProgress(temp);
  }, [documents, props.progress]);

  const upload = async e => {
    e.preventDefault();

    if (englishFile.size > 2 * 1024 * 1024) {
      alert("English file is too big.")
      throw new Error("English file is too big.");
    }
    
    if (foreignFile.size > 2 * 1024 * 1024) {
      alert("Foreign file is too big.")
      throw new Error("Foreign file is too big.");
    }

    if ((englishFile.size + foreignFile.size + customerData?.storedDocs) > 15 * 1024 * 1024) {
      alert("Files would place you over the limit.")
      throw new Error("Files would place you over the limit.");
    }

    const newDocRef = await push(docRef, {
      author: bookAuthor,
      name: bookName,
      createdOn: new Date().toISOString(),
      language: bookLanguage
    })

    const foreignRef = storageRef(storage, `customers/${props.uid}/docs/${newDocRef.key}-foreign.epub`)
    const resultForeign = await uploadFile(foreignRef, foreignFile);
    console.log(resultForeign);

    const englishRef = storageRef(storage, `customers/${props.uid}/docs/${newDocRef.key}-english.epub`)
    await uploadFile(englishRef, englishFile);
    console.log(englishRef);
  }

  return (
    <div className="documents">
      <Header pro={props.pro} />
      {JSON.stringify(documentsProgress)}
      {!props.pro ? 
        <div>Get pro to store documents.</div> :
        <div>
          <u>Documents</u>
          <form onSubmit={upload}>
            <div>
              <b>Book name</b>&nbsp;
              <input
                required
                type="text"
                onChange={(e) => setBookName(e.target.value)}
              />
            </div>
            <div>
              <b>Book author (optional)</b>&nbsp;
              <input
                type="text"
                onChange={(e) => setBookAuthor(e.target.value)}
              />
            </div>
            <div>
              <b>Book language</b>&nbsp;
              <select onChange={e => setBookLanguage(e.target.value)}>
                <option value="other">Other</option>
                <option value="afrikaans">Afrikaans</option>
                <option value="albanian">Albanian</option>
                <option value="arabic">Arabic</option>
                <option value="armenian">Armenian</option>
                <option value="basque">Basque</option>
                <option value="bengali">Bengali</option>
                <option value="bulgarian">Bulgarian</option>
                <option value="catalan">Catalan</option>
                <option value="literary-chinese">Chinese (Literary)</option>
                <option value="mandarin">Chinese (Mandarin)</option>
                <option value="czech">Czech</option>
                <option value="danish">Danish</option>
                <option value="dutch">Dutch</option>
                <option value="estonian">Estonian</option>
                <option value="fijian">Fijian</option>
                <option value="finnish">Finnish</option>
                <option value="french">French</option>
                <option value="georgian">Georgian</option>
                <option value="german">German</option>
                <option value="greek">Greek</option>
                <option value="ancient-greek">Greek (Ancient)</option>
                <option value="gujarati">Gujarati</option>
                <option value="hebrew">Hebrew</option>
                <option value="hindi">Hindi</option>
                <option value="hungarian">Hungarian</option>
                <option value="icelandic">Icelandic</option>
                <option value="indonesian">Indonesian</option>
                <option value="irish">Irish</option>
                <option value="italian">Italian</option>
                <option value="japanese">Japanese</option>
                <option value="javanese">Javanese</option>
                <option value="khmer">Khmer</option>
                <option value="korean">Korean</option>
                <option value="latin">Latin</option>
                <option value="latvian">Latvian</option>
                <option value="lithuanian">Lithuanian</option>
                <option value="macedonian">Macedonian</option>
                <option value="malay">Malay</option>
                <option value="malayalam">Malayalam</option>
                <option value="maltese">Maltese</option>
                <option value="maori">Maori</option>
                <option value="marathi">Marathi</option>
                <option value="mongolian">Mongolian</option>
                <option value="nepali">Nepali</option>
                <option value="norwegian">Norwegian</option>
                <option value="persian">Persian</option>
                <option value="polish">Polish</option>
                <option value="portuguese">Portuguese</option>
                <option value="punjabi">Punjabi</option>
                <option value="quechua">Quechua</option>
                <option value="romanian">Romanian</option>
                <option value="russian">Russian</option>
                <option value="samoan">Samoan</option>
                <option value="sanskrit">Sanskrit</option>
                <option value="serbo-croatian">Serbo-Croatian</option>
                <option value="slovak">Slovak</option>
                <option value="slovene">Slovene</option>
                <option value="spanish">Spanish</option>
                <option value="swahili">Swahili</option>
                <option value="swedish">Swedish</option>
                <option value="tamil">Tamil</option>
                <option value="tatar">Tatar</option>
                <option value="telugu">Telugu</option>
                <option value="thai">Thai</option>
                <option value="tibetan">Tibetan</option>
                <option value="tongan">Tongan</option>
                <option value="turkish">Turkish</option>
                <option value="ukrainian">Ukrainian</option>
                <option value="urdu">Urdu</option>
                <option value="uzbek">Uzbek</option>
                <option value="vietnamese">Vietnamese</option>
                <option value="welsh">Welsh</option>
                <option value="xhosa">Xhosa</option>
              </select>
            </div>
            <div>
              <b>Foreign book file</b>&nbsp;
              <input
                required
                type="file"
                onChange={(e) => {
                  const file = e.target.files ? e.target.files[0] : undefined;
                  setForeignFile(file);
                }}
              />
            </div>
            <div>
              <b>English book file</b>&nbsp;
              <input
                required
                type="file"
                onChange={(e) => {
                  const file = e.target.files ? e.target.files[0] : undefined;
                  setEnglishFile(file);
                }}
              />
            </div>
            <button type="submit">Create</button>
          </form>
          <div>
            Stored data: {(customerData?.storedDocs ? customerData.storedDocs : 0) / (1024 * 1024)} MB
          </div>
          <DocumentTable uid={props.uid} data={documentsProgress} />
        </div>
      }
    </div>
  )
}

export default Documents;