import { useState, useEffect } from 'react';
import "../CSS/Library.css";
import LibraryTable from './LibraryTable';
import Header from './Header';

function Library(props) {
  return (
    <div className="library">
      <Header pro={props.pro} />
      <div className="tableContainer">{props.bookInfo && <LibraryTable data={props.bookInfo} />}</div>
    </div>
  )
}

export default Library;