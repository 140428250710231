import Header from "./Header";
import "../CSS/Flashcards.css";
import { app } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from 'firebase/auth';
import { ref, getDatabase } from 'firebase/database';
import { useListVals } from 'react-firebase-hooks/database';
import { useEffect, Fragment, useState } from "react";
import reactStringReplace from "react-string-replace";
import { getFunctions, httpsCallable, 
  // connectFunctionsEmulator 
} from "firebase/functions";

const auth = getAuth(app);
const db = getDatabase(app);
const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "localhost", 5001);

function Flashcards(props) {
  const [user] = useAuthState(auth);

  const [cards] = useListVals(ref(db, `customers/${user.uid}/cards`), {keyField: "id"});

  function forHumans(seconds) {
    var levels = [
        [Math.floor((seconds % 31536000) / 86400), 'days'],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes']
    ];
    var returnArray = [];
    var returntext = '';

    for (var i = 0, max = levels.length; i < max; i++) {
        if ( levels[i][0] === 0 ) continue;
        returnArray.push(levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1) : levels[i][1]));
    };
    if (returnArray.length === 3)
      returntext = `${returnArray[0]}, ${returnArray[1]}, and ${returnArray[2]}`;
    else if (returnArray.length === 2)
      returntext = `${returnArray[0]} and ${returnArray[1]}`;
    else if (returnArray.length === 1)
      returntext = `${returnArray[0]}`;

    return returntext;
  }

  const [soonest, setSoonest] = useState("");

  const [card, setCard] = useState();
  useEffect(() => {
    const functionRef = httpsCallable(functions, "reviewStatus");
    functionRef({}).then(({ data }) => {
      setCard(data.card);
      setStats(data.stats);
      if (data.soonest)
        setSoonest(forHumans(new Date(data.soonest).getTime()/1000 - new Date().getTime()/1000));
      else
        setSoonest("");
    })
  }, [])

  const [stats, setStats] = useState();

  const [back, setBack] = useState(false);

  const review = rating => {
    const functionRef = httpsCallable(functions, "reviewAdd");
    functionRef({master: card.master, rating}).then(({ data }) => {
      setCard(data.card);
      setStats(data.stats);
      if (data.soonest)
        setSoonest(forHumans(new Date(data.soonest).getTime()/1000 - new Date().getTime()/1000));
      else
        setSoonest("");
      setBack(false);
    })
  };

  const deleteFlashcard = id => {
    const functionRef = httpsCallable(functions, "flashcardDelete");
    functionRef({ id }).then(({ data }) => {
      setCard(data.card);
      setStats(data.stats);
      if (data.soonest)
        setSoonest(forHumans(new Date(data.soonest).getTime()/1000 - new Date().getTime()/1000));
      else
        setSoonest("");
      setBack(false);
    });
  }

  return (
    <div className="flashcards">
      <Header pro={props.pro} />
      <div className="flashcardColumn left">
        <h3>Flashcard List</h3>
        <div>
          {cards?.map(card => (
            <Fragment key={card.id}>
              <div><b>{card.foreign}</b> <button onClick={() => deleteFlashcard(card.id)}>X</button></div>
              <div>{card.context}</div>
              <div><i className="blur unHoverBlur">{card.english}</i></div>
              <hr />
            </Fragment>
          ))}
        </div>
      </div>
      <div className="flashcardColumn right">
        <h3>Flashcards to Review</h3>
        {card ?
          <div>
            <div className="flashcard">
              {card?.front && <div>{reactStringReplace(card.front[0], card.back[0], (m, i) => <u key={i}>{m}</u>)}</div>}
              {back && card?.back &&
                <div><hr />{card.back[1]}</div>
              }
            </div>
            <hr />
            {!back ?
              <button onClick={() => setBack(true)}>Flip Card</button> :
              <div>
                <div>How easily did you remember?</div>
                <button onClick={() => review("again")}>Forgot</button>
                <button onClick={() => review("hard")}>Hard</button>
                <button onClick={() => review("good")}>Good</button>
                <button onClick={() => review("easy")}>Easy</button>
              </div>}
          </div> : 
          <div>
            No cards left to study, good job! Come back {soonest ? <span>in <b>{soonest}</b></span> : "later"} for more cards.
          </div>
        }
        <hr />
        {stats && <div>Learning: {stats.learning} Due: {stats.due} Overdue: {stats.overdue} Later: {stats.later}</div>}
      </div>
    </div>
  )
}

export default Flashcards;