import "../../CSS/LoginRegister.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { app } from '../../firebase';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import googleIcon from "../../Icons/google.svg";

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function Login() {
  const [errorCode, setErrorCode] = useState(0);
  const [error, setError] = useState("");

  const handleGoogle = e => {
    signInWithPopup(auth, provider)
    .catch((error) => {
      setErrorCode(error.code);
      setError(error.message);
    });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    if (password === passwordAgain) {
      createUserWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        setErrorCode(error.code);
        setError(error.message);
      });
    }
  };

  return (
    <div>
      <p><Link to="/home">Home</Link></p>
      <p>Register</p>
      <div onClick={handleGoogle} className="googleButton">
        <img alt="Google icon" src={googleIcon} className="googleButtonIcon" />
        <span className="googleButtonText">Sign up with Google</span>
      </div>
      <form onSubmit={handleSubmit}>
        <p><input value={email} onChange={e => setEmail(e.target.value)} type="text" placeholder="email"></input></p>
        <p><input value={password} onChange={e => setPassword(e.target.value)} type="text" placeholder="password"></input></p>
        <p><input value={passwordAgain} onChange={e => setPasswordAgain(e.target.value)} type="text" placeholder="password"></input></p>
        <button type="submit">Submit</button>
      </form>
      {error && <p>Error: {error} {errorCode}</p>}
    </div>
  )
}

export default Login;