const languageCodes = {
  "other": "xx",
  "afrikaans": "af",
  "albanian": "sq",
  "arabic": "ar",
  "armenian": "hy",
  "basque": "eu",
  "bengali": "bn",
  "bulgarian": "bg",
  "catalan": "ca",
  "literary-chinese": "lzh",
  "mandarin": "cmn",
  "czech": "cs",
  "danish": "da",
  "dutch": "nl",
  "english": "en",
  "estonian": "et",
  "fijian": "fj",
  "finnish": "fi",
  "french": "fr",
  "georgian": "ka",
  "german": "de",
  "greek": "el",
  "ancient-greek": "grc",
  "gujarati": "gu",
  "hebrew": "he",
  "hindi": "hi",
  "hungarian": "hu",
  "icelandic": "is",
  "indonesian": "id",
  "irish": "ga",
  "italian": "it",
  "japanese": "ja",
  "javanese": "jv",
  "khmer": "km",
  "korean": "ko",
  "latin": "la",
  "latvian": "lv",
  "lithuanian": "lt",
  "macedonian": "mk",
  "malay": "ms",
  "malayalam": "ml",
  "maltese": "mt",
  "maori": "mi",
  "marathi": "mr",
  "mongolian": "mn",
  "nepali": "ne",
  "norwegian": "no",
  "persian": "fa",
  "polish": "pl",
  "portuguese": "pt",
  "punjabi": "pa",
  "quechua": "qu",
  "romanian": "ro",
  "russian": "ru",
  "samoan": "sm",
  "sanskrit": "sa",
  "serbo-croatian": "sh",
  "slovak": "sk",
  "slovene": "sl",
  "spanish": "es",
  "swahili": "sw",
  "swedish": "sv",
  "tamil": "ta",
  "tatar": "tt",
  "telugu": "te",
  "thai": "th",
  "tibetan": "bo",
  "tongan": "to",
  "turkish": "tr",
  "ukrainian": "uk",
  "urdu": "ur",
  "uzbek": "uz",
  "vietnamese": "vi",
  "welsh": "cy",
  "xhosa": "xh",
}

export default languageCodes;